import React, { createContext, useReducer } from "react"

export const LanguageContext = createContext()

const initialState = { french: false }

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_TO_FRENCH":
      return {
        ...state,
        french: true,
      }

    case "CHANGE_TO_ENGLISH":
      return {
        ...state,
        french: false,
      }

    default:
      return state
  }
}

export const LanguageContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <LanguageContext.Provider value={[state, dispatch]}>
      {props.children}
    </LanguageContext.Provider>
  )
}
