exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/defaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-report-js": () => import("./../../../src/templates/report.js" /* webpackChunkName: "component---src-templates-report-js" */),
  "component---src-templates-suppliers-js": () => import("./../../../src/templates/suppliers.js" /* webpackChunkName: "component---src-templates-suppliers-js" */)
}

